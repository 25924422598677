<template>
  <div class="main">
    <div class="header">
      <div class="left">
        <button
          style="background-color: rgb(16, 39, 46)"
          class="left-button"
          @click="ToMap()"
        >
          返回
        </button>
      </div>
      <p class="title">智 慧 平 台 数 据 驾 驶 舱</p>
      <div class="header-right">
        <div class="time">{{ time }}</div>
        <div class="avatar">
          <el-popover
            placement="top"
            width="160"
            v-model="visible"
            trigger="hover"
          >
            <p style="padding: 10px 0px">确定要退出登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="cancelLogout()"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="logout()"
                >确定</el-button
              >
            </div>
            <!-- <img src="../../assets/home/avatar.png" alt="" slot="reference" /> -->
            <img
              style="width: 2.5rem; border-radius: 50%; border-color: black"
              src="../../assets/home/logo.jpg"
              alt=""
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
    </div>
    <div class="content">
      <p class="content-title">模块列表</p>
      <div class="content-table">
        <el-table
          :data="onlineDevData"
          style="width: 100%"
          header-row-class-name="tableHeaderColor"
          :cell-style="cellStyle"
        >
          <el-table-column
            prop="moduleName"
            label="模块名称"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <div class="name-cell">{{ row.moduleName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="modbusNumber"
            label="modbus站点号"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="moduleType"
            label="模块类型"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            prop="phaseType"
            label="相位类型"
            show-overflow-tooltip
            align="center"
          >
          <template slot-scope="scope">
              {{ scope.row.phaseType==0?'NONE': scope.row.phaseType==1?'A相'
              :scope.row.phaseType==2?'B相': scope.row.phaseType==4?'C相'
              :scope.row.phaseType==8?'ABC相':''}}
            </template>
          </el-table-column>
          <el-table-column
            prop="ratedCurrent"
            label="额定电流"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              {{ fixedNumber(scope.row.ratedCurrent, 2) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="overVoltage"
            label="过压"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              {{ fixedNumber(scope.row.overVoltage, 0) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="underVoltage"
            label="欠压"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              {{ fixedNumber(scope.row.underVoltage, 0) }}
            </template>
          </el-table-column>
          <el-table-column
            label="更多参数"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                class="params-cell"
                type="text"
                size="small"
                style="color: rgb(28, 212, 212); font-size: 1vw"
                @click="handleRead(scope.row.id, scope.row.deviceId)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging" v-if="pages > 1">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalPage"
            style="
              background-color: #fff;
              text-align: right;
              padding: 0px !important;
            "
          >
          </el-pagination>
        </div>
        <div class="content-table-footer"></div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      // 时间显示
      time: "",
      // 定时器
      setTime: "",
      // 退出登录模块是否显示
      visible: false,
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      // 总页码
      pages: 1,
      // 总条数
      totalPage: 1,
      // 模块列表数组
      onlineDevData: [],
    };
  },
  created() {
    let that = this;
    // @ts-ignore
    this.setTime = setInterval(() => {
      that.Date();
    }, 1000);
  },
  mounted() {
    this.initDevTable();
  },
  methods: {
    // 保留几位小数的方法
    fixedNumber(number, i) {
      return Number(number).toFixed(i);
    },
    // 初始化模块表格
    initDevTable() {
      var that = this;
      let param = {
        pageNum: that.pagination.page,
        pageSize: that.pagination.pageSize,
        deviceId: localStorage.getItem("modelID"),
        // deviceId:19
      };
      this.$post("/module/pageList", param)
        .then((res) => {
          console.log(res);
          this.onlineDevData = res.data.list.reverse();
          this.totalPage = res.data.total;
          this.pages = res.data.pages;
        })
        .catch(() => {
          console.log("异常");
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.initDevTable();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagination.page = val;
      this.initDevTable();
    },

    // 返回按钮
    ToMap() {
      this.$router.push("/Citydevice");
    },
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;

      this.time =
        date.year +
        "年" +
        newmonth +
        "月" +
        newday +
        "日" +
        newHours +
        ":" +
        newminutes +
        ":" +
        newseconds;
    },
    // 退出登录
    logout() {
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
    // 查看参数
    handleRead(id, deviceId) {
      localStorage.setItem("ModelDetailId", id);
      localStorage.setItem("ModelDetailDeviceId", deviceId);
      this.$router.push("/ModelDetail");
    },
    // 表格单元样式
    cellStyle() {
      return "background: rgb(16, 39, 46) !important";
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>
  
  <style lang="scss" scoped>
// 表头
::v-deep .tableHeaderColor th {
  background: rgb(16, 39, 46) !important;
  color: rgb(211, 230, 248);
  font-family: PingFang SC;
  font-size: 0.93vw;
  line-height: 21px;
  letter-spacing: 0px;
  padding: auto 30px;
}

//滚动条的宽度
::v-deep ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #e4e4e4;
  border-radius: 3px;
}

//滚动条的滑块
::v-deep ::-webkit-scrollbar-thumb {
  background-color: #033d3d !important;
  border-radius: 3px;
}
//  分页器
.paging {
  margin-top: 20px;
  // border: 1px solid rgba(11, 166, 167,0.3);
  background: rgb(16, 39, 46) !important;
  padding: 10px 20px;
}
::v-deep .el-pagination {
  background: rgb(16, 39, 46) !important;
  .el-input__inner {
    background: #033d3d !important;
    color: #fff !important;
    border: 1px solid rgba(11, 166, 167, 0.3);
  }
  .el-pager li {
    background: #033d3d !important;
    color: #fff !important;
  }
  .el-pager li.active {
    color: #409eff !important;
  }
  .el-pagination__jump {
    color: #fff !important;
  }
}
::v-deep .el-pagination button {
  background: #033d3d !important;
}
::v-deep .el-pagination__total {
  color: #fff !important;
}
.main {
  background: url("../../assets/drive/big-screen-backimage.svg");
  margin: 0px; //外边距0
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  zoom: 1;
  background-color: #07080e;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  color: #fff;
  overflow: auto;
  .header {
    display: flex;
    .left {
      position: absolute;
      top: 3.5vh;
      left: 1.563vw;
      display: flex;
      align-items: center;

      .left-button {
        display: inline-block;
        height: 4vh;
        line-height: 4vh;
        border: 1px solid rgb(11, 166, 167);
        box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
        // background: #000;
        color: rgb(195, 226, 249);
        font-size: 1.04vw;
        border-radius: 7px;
        padding: 0 0.52vw;
        white-space: nowrap;
        box-sizing: border-box;
      }

      .area {
        // width: 28vw;
        display: flex;
        margin-left: 10px;

        .area-title {
          color: rgb(195, 226, 249);
          font-family: PingFang SC;
          font-size: 1.14vw;
          letter-spacing: 0px;
          text-align: center;
          margin: auto 5px;
          vertical-align: middle;
        }

        .area-select {
          height: 4vh;
          box-sizing: border-box;

          select {
            outline: none;
            appearance: none;
            /* 去掉默认图标 */
            -moz-appearance: none;
            /* Firefox */
            -webkit-appearance: none;
            background: url("../../assets/drive/drop-box.png") no-repeat scroll 95%
              50% transparent;
            background-size: 0.781vw;
            width: 5.5vw;
            height: 4vh;
            box-sizing: border-box;
            padding: 0 10px;
            font-size: 1.04vw;
            border: 1px solid rgb(11, 166, 167);
            box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
            color: rgb(195, 226, 249);
            font-family: PingFang SC;
            border-radius: 7px;
          }
        }
      }
    }

    .title {
      width: 100%;
      text-align: center;
      // margin-left: -0.5vw;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 1),
        rgba(113, 244, 236, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
      font-family: PingFang SC;
      font-size: 2.4vw;
      cursor: pointer;
    }

    .header-right {
      position: absolute;
      right: 1.563vw;
      top: 3.5vh;
      width: 25%;
      display: flex;
      align-items: center;

      .time {
        // margin-left: 20px;
        width: 80vw;
        text-align: right;
        color: rgb(195, 226, 249);
        font-family: PingFang;
        font-size: 1.04vw;
        line-height: 26px;
        letter-spacing: 0px;
        cursor: pointer;
      }

      .avatar {
        margin: 0 0 0 15px;
        width: 10vw;
      }
    }
  }

  // 表格
  .content {
    margin: 6vh 1.563vw;
    font-size: 1.302vw;

    .content-title {
      margin-bottom: 10px;
      cursor: pointer;
    }

    // 设备控制按钮
    .device-btn {
      display: inline-block;
      height: 4vh;
      line-height: 4vh;
      border: 1px solid rgb(11, 166, 167);
      box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
      background: #000;
      color: rgb(195, 226, 249);
      font-size: 1.04vw;
      border-radius: 7px;
      padding: 0 0.52vw;
      white-space: nowrap;
      box-sizing: border-box;
      // margin-bottom: 20px;
    }

    .content-table {
      position: relative;
      border: 2px solid rgba(98, 255, 246, 0.1);
      padding: 10px 10px 50px;
      min-height: 64vh;
      background: linear-gradient(
        180deg,
        rgba(19, 32, 36, 1),
        rgba(98, 255, 246, 0.1)
      );
      margin-bottom: 30px;

      &::before {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        left: -2px;
        top: -1px;
        border-left: 2px solid rgb(35, 238, 231);
        border-top: 2px solid rgb(35, 238, 231);
      }

      &::after {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        right: -2px;
        top: -1px;
        border-right: 2px solid rgb(35, 238, 231);
        border-bottom: 2px solid rgb(35, 238, 231);
      }

      .content-table-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &::before {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          left: -2px;
          bottom: -1px;
          border-left: 2px solid rgb(35, 238, 231);
          border-bottom: 2px solid rgb(35, 238, 231);
        }

        &::after {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          right: -2px;
          bottom: -1px;
          border-right: 2px solid rgb(35, 238, 231);
          border-bottom: 2px solid rgb(35, 238, 231);
        }
      }

      /*最外层透明*/
      ::v-deep .el-table,
      ::v-deep .el-table__expanded-cell {
        background-color: transparent !important;
      }

      /* 表格内背景颜色 */
      ::v-deep .el-table th,
      ::v-deep .el-table tr,
      ::v-deep .el-table td {
        // background-color: transparent !important;
        border: 0; //去除表格
        //   height: 7vh !important;
      }

      // 表格内字体
      ::v-deep .el-table {
        color: rgb(211, 230, 248);
        font-family: PingFang SC;
        font-size: 0.93vw;
        //   line-height: 7vh;
        letter-spacing: 0px;
        text-align: center;

        &::before {
          display: none;
        }

        // 表格行距
        .el-table__body {
          -webkit-border-vertical-spacing: 10px; // 垂直间距 设置的是行间距
        }

        thead th,
        tbody .el-table__row td {
          padding: 10px 0;
        }
      }

      // 表格底部边框
      .el-table::before {
        background-color: transparent !important;
      }
    }
  }
}
</style>
 
  